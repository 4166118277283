import { type ReactElement } from 'react';

interface ITooltip {
  children: ReactElement;
  tooltipsText: string;
  position: 'top' | 'right' | 'bottom' | 'left';
  variant?: 'default' | 'navbar';
  className?: string;
  colorClass?: string;
}

export default function Tooltip({
  children,
  tooltipsText,
  position,
  variant = 'navbar',
  colorClass = 'bg-indigo-600',
  className = '',
}: ITooltip): ReactElement {
  if (!colorClass.includes('text')) {
    colorClass += ' text-white';
  }

  return (
    <span className={'relative'}>
      <div className={'peer'}>{children}</div>
      <div
        className={`${colorClass} ${className} absolute z-20 whitespace-nowrap rounded py-[6px] px-4 text-sm font-semibold hidden peer-hover:block ${
          (position === 'right' && `left-full top-1/2 ml-3 -translate-y-1/2`) ||
          (position === 'top' && `bottom-full left-1/2 mb-3 -translate-x-1/2`) ||
          (position === 'left' && `right-full top-1/2 mr-3 -translate-y-1/2`) ||
          (position === 'bottom' && `top-full left-1/2 mt-3 -translate-x-1/2`)
        }`}
      >
        <span
          className={`${colorClass} absolute -z-10 h-2 w-2 rotate-45 ${
            (position === 'right' && `left-[-3px] top-1/2 -translate-y-1/2`) ||
            (position === 'top' && `bottom-[-3px] left-1/2 -translate-x-1/2`) ||
            (position === 'left' && `right-[-3px] top-1/2 -translate-y-1/2`) ||
            (position === 'bottom' && `top-[-3px] left-1/2 -translate-x-1/2`)
          } `}
        ></span>
        {tooltipsText.split('\n').map(text => (
          <p key={text}>{text}</p>
        ))}
      </div>
    </span>
  );
}
